<template>
    <div class="display-block">
        <h3>Coupon</h3>
        <v-card>
            <v-card-text>
                <h3>Coupon :</h3>
                <row-item label="Code  " :value="coupon.code"></row-item>
                <row-item label="Coupon Type " :value="coupon.couponType"></row-item>
                <row-item v-if="coupon.couponType=='SPLUSR'" label="User Name " :value="coupon.user.displayName"></row-item>
                <row-item v-if="coupon.couponType=='PRODUCT'" label="Product Title " :value="coupon.product.displayName"></row-item>
                <row-item v-if="coupon.couponType=='BRAND'" label="Brand Title " :value="coupon.brand.displayName"></row-item>
                <row-item v-if="coupon.couponType=='CATEGORY'" label="Category Title " :value="coupon.category.displayName"></row-item>
                <row-item label="Discount Type  " :value="coupon.discountType"></row-item>
                <row-item v-if="coupon.discountType=='PERCENT'" label="Percent" :value="coupon.percent"></row-item>
                <row-item v-else label="Amount" :value="coupon.amount"></row-item>
                <row-item label="Max Discount Amount" :value="coupon.maxDiscountAmount"></row-item>
                <row-item label="Max Usage" :value="coupon.maxUsageOfCoupon"></row-item>
                <row-item label="Minimun Order Amount" :value="coupon.minOrderAmount"></row-item>
                <row-item label="Start Date" :value="coupon.startDate|dateFormat"></row-item>
                <row-item label="Expiry Date" :value="coupon.expiryDate|dateFormat"></row-item>
            </v-card-text>
                <v-flex text-right><v-btn @click="$router.go(-1)" class="ma-2" :style="theme">Back</v-btn></v-flex>
        </v-card>
    </div>
</template>

<script>
import appConstants from '@/utils/appConstants'
import axios from "axios";
import RowItem from '@/components/RowItem'
export default {
    components: {
        RowItem,
    },
    data() {
        return {
            id:0,
            coupon: {
                code: "",
                couponType:'',
                /* startDate:'',
                expiryDate:'', */
                discountType:'',
                percent:0,
                amount:0,
                maxDiscountAmount:0,
                vendorId:'',
                userId:'',
                maxUsageOfCoupon:0,
                productCode:'',
                brandId:'',
                minOrderAmount:0,
                isActive:true,
            },
        }
    },
    mounted() {
        this.initComponent();
    },
    methods: {
        async initComponent() {
            this.id = this.$route.params.id
                this.coupon = await this.getItem(appConstants.COUPONS_API + "/" + this.id)
        },
    }
}
</script>
